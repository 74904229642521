<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <json-viewer
                v-if="form.config_json"
                :value="form.config_json"
                :expanded="true"
                :expandDepth="10"
            ></json-viewer>
            <b-row v-if="form.type != 'configurable'">
                <b-col sm="12" class="text-xs-center">
                    <b-row align-h="end">
                        <b-col sm="3" class="text-sm-right">
                            <b-button block @click="rebuild()" variant="primary">
                                {{ $t('common.button.generate') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>

    </validation-observer>
</template>


<script>
import {mapGetters} from 'vuex'
import ImageMappingBlock from "@/components/article/ImageMappingBlock";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwMedia from "@/components/ZwMedia";

export default {
    name: 'ConfigJsonTab',
    mixins: [commonSave],
    components: {ImageMappingBlock, ZwMedia},
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            form: {},
            loading: true,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle']),
        shown() {
            const articles = this.$store.dispatch('Articles/fetchArticle', this.articleId)
            Promise.all([articles])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getArticle()}))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        rebuild() {
            this.loading = true
            this.$store.dispatch('Articles/regenerateJsonConfig', this.form)
                .then((response) => {
                    this.shown()
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>